import { createRouter, createWebHistory } from "vue-router"
import Permissions from "../services/PermissionsService"

const routes = [
  {
    path: "/",
    name: "Login",
    component: () =>
        import(/* webpackChunkName: "group-login-user" */ "../views/LoginUserView")
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
        import(/* webpackChunkName: "group-dashboard" */ "../views/DashboardView"),
    meta: { requiresAuth: true }
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: () =>
        import(/* webpackChunkName: "group-accounts" */ "../views/accounts/AccountsView"),
    meta: { pageCategory: "Accounts", requiresAuth: true }
  },
  {
    path: "/accounts/create",
    name: "Create Account",
    component: () =>
        import(/* webpackChunkName: "group-accounts" */ "../views/accounts/CreateAccountView"),
    meta: { pageCategory: "Accounts", requiresAuth: true, requiresWriteAccess: true },
    // Children show in this component"s router-view tags
    children: [
      {
        path: "",
        name: "Account Details",
        component: () =>
            import(/* webpackChunkName: "group-accounts" */ "../components/forms/accounts/CreateAccountDetails")
      },
      {
        path: "address",
        name: "Account Address",
        component: () =>
            import(/* webpackChunkName: "group-accounts" */ "../components/forms/accounts/CreateAccountAddress"),
        beforeEnter: (to, from, next) => {
          // Check user is coming from goods in details:
          if (from.name === "Account Details") next()
          else next("not-found")
        },
      },
    ],
  },
  {
    path: "/accounts/edit/:id",
    name: "Account Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-accounts" */ "../views/accounts/EditAccountView"),
    meta: { pageCategory: "Accounts", requiresAuth: true, requiresWriteAccess: true }
  },
  {
    path: "/app-config",
    name: "App Config",
    component: () =>
        import(/* webpackChunkName: "group-settings" */ "../views/AppConfigView"),
    meta: { pageCategory: "App Config", requiresAuth: true }
  },
  {
    path: "/addresses",
    name: "Addresses",
    component: () =>
        import(/* webpackChunkName: "group-settings" */ "../views/AddressesView"),
    meta: { pageCategory: "Addresses", requiresAuth: true }
  },
  {
    path: "/containers",
    name: "Containers",
    component: () =>
        import(/* webpackChunkName: "group-containers" */ "../views/containers/ContainersView"),
    meta: { pageCategory: "Containers", requiresAuth: true }
  },
  {
    path: "/containers/edit/:id",
    name: "Container Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-containers" */ "../views/containers/EditContainersView"),
    meta: { pageCategory: "Containers", requiresAuth: true, requiresWriteAccess: true }
  },
  {
    path: "/container-types",
    name: "Container Types",
    component: () =>
        import(/* webpackChunkName: "group-containers" */ "../views/ContainerTypesView"),
    meta: { pageCategory: "Container Types", requiresAuth: true }
  },
  {
    path: "/database-backups",
    name: "Database Backups",
    component: () =>
        import(/* webpackChunkName: "group-settings" */ "../views/DatabaseBackupsView"),
    meta: { pageCategory: "Database Backups", requiresAuth: true }
  },
  {
    path: "/dispatches",
    name: "Dispatches",
    component: () =>
        import(/* webpackChunkName: "group-orders" */ "../views/DispatchesView"),
    meta: { pageCategory: "Dispatches", requiresAuth: true }
  },
  {
    path: "/document-templates",
    name: "Document Templates",
    component: () =>
        import(/* webpackChunkName: "group-settings" */ "../views/DocumentTemplatesView"),
    meta: { pageCategory: "Document Templates", requiresAuth: true }
  },
  {
    path: "/goods-in-dashboard",
    name: "Goods In Dashboard",
    component: () =>
        import(/* webpackChunkName: "group-dashboards" */ "../views/goods-in/GoodsInDashboardView"),
    meta: { pageCategory: "Goods In Dashboard", requiresAuth: true },
  },
  {
    path: "/goods-in",
    name: "Goods In",
    component: () =>
        import(/* webpackChunkName: "group-goods-in" */ "../views/goods-in/GoodsInView"),
    meta: { pageCategory: "Goods In", requiresAuth: true },
  },
  {
    path: "/goods-in/:customFilter",
    name: "Goods In Filtered",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-goods-in" */ "../views/goods-in/GoodsInView"),
    meta: { pageCategory: "Goods In", requiresAuth: true },
  },
  {
    path: "/goods-in/create",
    name: "Create Goods In",
    component: () =>
        import(/* webpackChunkName: "group-goods-in" */ "../views/goods-in/CreateGoodsInView"),
    meta: { pageCategory: "Goods In", requiresAuth: true, requiresWriteAccess: true },
    // Children show in this component"s router-view tags
    children: [
      {
        path: "",
        name: "Goods In Details",
        component: () =>
            import(/* webpackChunkName: "group-goods-in" */ "../components/forms/goods-in/CreateGoodsInDetails")
      },
      {
        path: "items",
        name: "Goods In Items",
        component: () =>
            import(/* webpackChunkName: "group-goods-in" */ "../components/forms/goods-in/CreateGoodsInItems"),
        beforeEnter: (to, from, next) => {
          // Check user is coming from goods in details:
          if (from.name === "Goods In Details") next()
          else next("not-found")
        },
      },
    ],
  },
  {
    path: "/goods-in-asn",
    name: "Goods In ASN",
    component: () =>
        import(/* webpackChunkName: "group-goods-in" */ "../views/goods-in/GoodsInAsnView"),
    meta: { pageCategory: "Goods In ASNs", requiresAuth: true },
  },
  {
    path: "/goods-in-asn/edit/:id",
    name: "Goods In ASN Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-goods-in" */ "../views/goods-in/EditGoodsInAsnView"),
    meta: { pageCategory: "Goods In ASNs", requiresAuth: true, requiresWriteAccess: true }
  },
  {
    path: "/goods-in/edit/:id",
    name: "Goods In Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-goods-in" */ "../views/goods-in/EditGoodsInView"),
    meta: { pageCategory: "Goods In", requiresAuth: true, requiresWriteAccess: true }
  },
  {
    path: "/inspection",
    name: "Inspection",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-quality" */ "../views/InspectionView"),
    meta: { requiresAuth: true }
  },
  {
    path: "/items",
    name: "Items",
    component: () =>
        import(/* webpackChunkName: "group-items" */ "../views/items/ItemsView"),
    meta: { pageCategory: "Items", requiresAuth: true }
  },
  {
    path: "/items/edit/:id",
    name: "Item Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-items" */ "../views/items/EditItemsView"),
    meta: { pageCategory: "Items", requiresAuth: true, requiresWriteAccess: true },
  },
  {
    path: "/item-categories",
    name: "Item Categories",
    component: () =>
        import(/* webpackChunkName: "group-items" */ "../views/ItemCategoriesView"),
    meta: { pageCategory: "Item Categories", requiresAuth: true }
  },
  {
    path: "/integrations",
    name: "Integrations",
    component: () =>
        import(/* webpackChunkName: "group-integrations" */ "../views/integrations/IntegrationsView"),
    meta: { pageCategory: "Integrations", requiresAuth: true }
  },
  {
    path: "/integrations/:id/:datatableName",
    name: "Integration Template",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-integrations" */ "../views/integrations/IntegrationTemplateView"),
    meta: { pageCategory: "Integrations", requiresAuth: true },
  },
  {
    path: "/kpis",
    name: "KPIs",
    component: () =>
        import(/* webpackChunkName: "group-kpi" */ "../views/kpis/KpisView"),
    meta: { pageCategory: "KPIs", requiresAuth: true }
  },
  {
    path: "/kpis/picking",
    name: "KPIs Picking",
    component: () =>
        import(/* webpackChunkName: "group-kpi" */ "../views/kpis/PickingKpisView"),
    meta: { pageCategory: "KPIs", requiresAuth: true }
  },
  {
    path: "/kpis/goodsin",
    name: "KPIs Goods In",
    component: () =>
        import(/* webpackChunkName: "group-kpi" */ "../views/kpis/GoodsInKpisView"),
    meta: { pageCategory: "KPIs", requiresAuth: true }
  },
  {
    path: "/locations",
    name: "Locations",
    component: () =>
        import(/* webpackChunkName: "group-locations" */ "../views/locations/LocationsView"),
    meta: { pageCategory: "Locations", requiresAuth: true }
  },
  {
    path: "/locations/edit/:id",
    name: "Location Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-locations" */ "../views/locations/EditLocationsView"),
    meta: { pageCategory: "Locations", requiresAuth: true, requiresWriteAccess: true },
  },
  {
    path: "/location-types",
    name: "Location Types",
    component: () =>
        import(/* webpackChunkName: "group-locations" */ "../views/LocationTypesView"),
    meta: { pageCategory: "Location Types", requiresAuth: true }
  },
  {
    path: "/location-dimensions",
    name: "Location Dimensions",
    component: () =>
        import(/* webpackChunkName: "group-locations" */ "../views/LocationDimensionsView"),
    meta: { pageCategory: "Location Dimensions", requiresAuth: true }
  },
  {
    path: "/movement-logs",
    name: "Movement Logs",
    component: () =>
        import(/* webpackChunkName: "group-logs" */ "../views/MovementLogsView"),
    meta: { pageCategory: "Movement Logs", requiresAuth: true }
  },
  {
    path: "/my-warehouse",
    name: "My Warehouse",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-warehouse" */ "../views/MyWarehouseView"),
    meta: { pageCategory: "My Warehouse", requiresAuth: true }
  },
  {
    path: "/non-conformance-reports",
    name: "Non Conformance Reports",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-quality" */ "../views/non-conformance-reports/NonConformanceReportsView"),
    meta: { requiresAuth: true }
  },
  {
    path: "/non-conformance-reports/create",
    name: "Create Non Conformance Report",
    component: () =>
        import(/* webpackChunkName: "group-quality" */ "../views/non-conformance-reports/CreateNcrView"),
    meta: { pageCategory: "Non Conformance Reports", requiresAuth: true, requiresWriteAccess: true },
    // Children show in this component"s router-view tags
    children: [
      {
        path: "",
        name: "NCR Details",
        component: () =>
            import(/* webpackChunkName: "group-quality" */ "../components/forms/non-conformance-reports/CreateNcrDetails")
      },
      {
        path: "items",
        name: "NCR Items",
        component: () =>
            import(/* webpackChunkName: "group-quality" */ "../components/forms/non-conformance-reports/CreateNcrItems"),
        beforeEnter: (to, from, next) => {
          // Check user is coming from NCR details:
          if (from.name === "NCR Details") next()
          else next("not-found")
        },
      },
    ],
  },
  {
    path: "/non-conformance-reports/edit/:id",
    name: "Edit Non Conformance Report",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-quality" */ "../views/non-conformance-reports/EditNcrView"),
    meta: { pageCategory: "Non Conformance Reports", requiresAuth: true, requiresWriteAccess: true },
  },
  {
    path: "/orders-dashboard",
    name: "Orders Dashboard",
    component: () =>
        import(/* webpackChunkName: "group-dashboards" */ "../views/orders/OrdersDashboardView"),
    meta: { pageCategory: "Orders Dashboard", requiresAuth: true },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
        import(/* webpackChunkName: "group-orders" */ "../views/orders/OrdersView"),
    meta: { pageCategory: "Orders", requiresAuth: true }
  },
  {
    path: "/orders/:customFilter",
    name: "Orders Filtered",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-orders" */ "../views/orders/OrdersView"),
    meta: { pageCategory: "Orders", requiresAuth: true }
  },
  {
    path: "/orders/create",
    name: "Create Order",
    component: () =>
        import(/* webpackChunkName: "group-orders" */ "../views/orders/CreateOrderView"),
    meta: { pageCategory: "Orders", requiresAuth: true, requiresWriteAccess: true },
    // Children show in this component"s router-view tags
    children: [
      {
        path: "",
        name: "Order Details",
        component: () =>
            import(/* webpackChunkName: "group-orders" */ "../components/forms/orders/CreateOrderDetails")
      },
      {
        path: "items",
        name: "Order Items",
        props: { dataTableEmptyMsg: "Add items to order", cancelRedirect: "Orders" },
        component: () =>
            import(/* webpackChunkName: "group-orders" */ "../components/forms/ItemsAndQtyDatatableForm"),
        beforeEnter: (to, from, next) => {
          // Check user is coming from order details to stop unauthorised access:
          if (from.name === "Order Details") next()
          else next("not-found")
        }
      }
    ],
  },
  {
    path: "/orders/edit/:id",
    name: "Order Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-orders" */ "../views/orders/EditOrderView"),
    meta: { pageCategory: "Orders", requiresAuth: true, requiresWriteAccess: true },
  },
  {
    path: "/pack-types",
    name: "Pack Types",
    component: () =>
        import(/* webpackChunkName: "group-items" */ "../views/PackTypesView")
  },
  {
    path: "/packing-containers",
    name: "Packing Containers",
    component: () =>
        import(/* webpackChunkName: "group-packing" */ "../views/PackingContainersView"),
    meta: { pageCategory: "Packing Containers", requiresAuth: true },
  },
  {
    path: "/picking-issues",
    name: "Picking Issues",
    component: () =>
        import(/* webpackChunkName: "group-picking" */ "../views/PickingIssuesView"),
  },
  {
    path: "/picking-settings",
    name: "Picking Settings",
    component: () =>
        import(/* webpackChunkName: "group-settings" */ "../views/PickingSettingsView"),
    meta: { pageCategory: "Picking Settings", requiresAuth: true }
  },
  {
    path: "/print-barcode",
    name: "Print Barcode",
    component: () =>
        import(/* webpackChunkName: "group-barcode" */ "../views/PrintBarcodeView")
  },
  {
    path: "/printers",
    name: "Printers",
    component: () =>
        import(/* webpackChunkName: "group-printers" */ "../views/PrintersView"),
    meta: { pageCategory: "Printers", requiresAuth: true }
  },
  {
    path: "/plugins",
    name: "Plugins",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-plugins" */ "../views/PluginsView"),
    meta: { pageCategory: "Plugins", requiresAuth: true }
  },
  {
    path: "/plugin/:id/:name/:url",
    name: "Plugin Template",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-plugins" */ "../views/PluginTemplateView"),
    meta: { requiresAuth: true }
  },
  {
    path: "/reason-codes",
    name: "Reason Codes",
    component: () =>
        import(/* webpackChunkName: "group-settings" */ "../views/ReasonCodesView"),
    meta: { pageCategory: "Reason Codes", requiresAuth: true }
  },
  {
    path: "/order-returns",
    name: "Order Returns",
    component: () =>
        import(/* webpackChunkName: "group-order-returns" */ "../views/order-returns/OrderReturnsView"),
    meta: { pageCategory: "Order Returns", requiresAuth: true },
  },
  {
    path: "/order-returns/create",
    name: "Create Order Return",
    component: () =>
        import(/* webpackChunkName: "group-order-returns" */ "../views/order-returns/CreateOrderReturnView"),
    meta: { pageCategory: "Order Returns", requiresAuth: true, requiresWriteAccess: true },
    children: [
      {
        path: "",
        name: "Order Return Details",
        component: () =>
            import(/* webpackChunkName: "group-order-returns" */ "../components/forms/order-returns/CreateOrderReturnDetails")
      },
      {
        path: "items",
        name: "Order Return Items",
        component: () =>
            import(/* webpackChunkName: "group-order-returns" */ "../components/forms/order-returns/CreateOrderReturnItems"),
        beforeEnter: (to, from, next) => {
          // Check user is coming from goods in details to stop unauthorised access:
          if (from.name === "Order Return Details") next()
          else next("not-found")
        }
      }
    ]
  },
  {
    path: "/order-returns/edit/:id",
    name: "Order Return Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-order-returns" */ "../views/order-returns/EditOrderReturnView"),
    meta: { pageCategory: "Order Returns", requiresAuth: true, requiresWriteAccess: true },
  },
  {
    path: "/reports",
    name: "Reports",
    component: () =>
        import(/* webpackChunkName: "group-reports" */ "../views/reports/ReportsView"),
    meta: { pageCategory: "Reports", requiresAuth: true }
  },
  {
    path: "/reports/picking-reports",
    name: "Picking Reports",
    component: () =>
        import(/* webpackChunkName: "group-picking-reports" */ "../views/reports/ReportsSubmenuView"),
    meta: {
      pageCategory: "Reports",
      requiresAuth: true,
      breadcrumb: [
        { label: "Picking Reports Menu", route: "/reports/picking-reports", disabled: true }
      ]
    },
    children: [
      {
        path: "",
        name: "Picking Reports Menu",
        component: () =>
            import(/* webpackChunkName: "group-picking-reports" */ "../components/reports/picking/PickingReportsMenu"),
      },
      {
        path: "picking-report",
        name: "Picking Report",
        component: () =>
            import(/* webpackChunkName: "group-picking-reports" */ "../components/reports/picking/PickingReport"),
        meta: {
          breadcrumb: [
            { label: "Picking Reports Menu", route: "/reports/picking-reports", disabled: false },
            { label: "Picking Report", route: "/reports/picking-reports/picking-report", disabled: true }
          ]
        },
      },
      {
        path: "item-pick-positions",
        name: "Item Pick Positions",
        component: () => import(/* webpackChunkName: "group-picking-reports" */ "../components/reports/picking/PickingItemPickPositions"),
        meta: {
          breadcrumb: [
            { label: "Picking Reports Menu", route: "/reports/picking-reports", disabled: false },
            { label: "Item Pick Positions", route: "/reports/picking-reports/item-pick-positions", disabled: true }
          ]
        },
      },
      {
        path: "often-short",
        name: "Often Short",
        component: () =>
            import(/* webpackChunkName: "group-picking-reports" */ "../components/reports/picking/PickingOftenShort"),
        meta: {
          breadcrumb: [
            { label: "Picking Reports Menu", route: "/reports/picking-reports", disabled: false },
            { label: "Often Short", route: "/reports/picking-reports/often-short", disabled: true }
          ]
        },
      },
      {
        path: "customer-fulfilment",
        name: "Customer Fulfilment",
        component: () =>
            import(/* webpackChunkName: "group-picking-reports" */ "../components/reports/picking/PickingCustomerFulfillment"),
        meta: {
          breadcrumb: [
            { label: "Picking Reports Menu", route: "/reports/picking-reports", disabled: false },
            { label: "Customer Fulfilment", route: "/reports/picking-reports/customer-fulfilment", disabled: true }
          ]
        }
      },
      {
        path: "customer-pick-report",
        name: "Customer Pick Report",
        component: () => import(/* webpackChunkName: "group-picking-reports" */ "../components/reports/picking/CustomerPickReport"),
        meta: {
          breadcrumb: [
            { label: "Picking Reports Menu", route: "/reports/picking-reports", disabled: false },
            { label: "Customer Pick Report", route: "/reports/picking-reports/customer-reports", disabled: true },
          ],
        },
      },
    ],
  },
  {
    path: "/reports/goods-in-reports",
    name: "Goods In Reports",
    component: () =>
        import(/* webpackChunkName: "group-goods-in-reports" */ "../views/reports/ReportsSubmenuView"),
    meta: {
      pageCategory: "Reports",
      requiresAuth: true,
      breadcrumb: [
        { label: "Goods In Reports Menu", route: "/reports/goods-in-reports", disabled: true }
      ]
    },
    children: [
      {
        path: "",
        name: "Goods In Reports Menu",
        component: () =>
            import(/* webpackChunkName: "group-goods-in-reports" */ "../components/reports/goods-in/GoodsInReportsMenu")
      },
      {
        path: "outstanding",
        name: "Goods In Outstanding",
        component: () =>
            import(/* webpackChunkName: "group-goods-in-reports" */ "../components/reports/goods-in/OutstandingGoodsIn"),
        meta: {
          breadcrumb: [
            { label: "Goods In Reports Menu", route: "/reports/goods-in-reports", disabled: false },
            { label: "Goods In Outstanding", route: "/reports/goods-in-reports/outstanding", disabled: true }
          ]
        },
      },
      {
        path: "no-pick-location",
        name: "No Pick Location",
        component: () =>
            import(/* webpackChunkName: "group-goods-in-reports" */ "../components/reports/goods-in/NoPickLocationSet"),
        meta: {
          breadcrumb: [
            { label: "Goods In Reports Menu", route: "/reports/goods-in-reports", disabled: false },
            { label: "No Pick Location", route: "/reports/goods-in-reports/no-pick-location", disabled: true }
          ]
        },
      },
      {
        path: "supplier-fulfilment",
        name: "Supplier Fulfilment",
        component: () =>
            import(/* webpackChunkName: "group-goods-in-reports" */ "../components/reports/goods-in/GoodsInSupplierFulfillment"),
        meta: {
          breadcrumb: [
            { label: "Goods In Reports Menu", route: "/reports/goods-in-reports", disabled: false },
            { label: "Supplier Fulfilment", route: "/reports/goods-in-reports/supplier-fulfilment", disabled: true }
          ]
        },
      },
      {
        path: "time",
        name: "Goods In By Time",
        component: () =>
            import(/* webpackChunkName: "group-goods-in-reports" */ "../components/reports/goods-in/GoodsInTime"),
        meta: {
          breadcrumb: [
            { label: "Goods In Reports Menu", route: "/reports/goods-in-reports", disabled: false },
            { label: "Goods In By Time", route: "/reports/goods-in-reports/time", disabled: true }
          ]
        },
      }
    ]
  },
  {
    path: "/reports/stock-reports",
    name: "Stock Reports",
    component: () =>
        import(/* webpackChunkName: "group-stock-reports" */ "../views/reports/ReportsSubmenuView"),
    meta: {
      pageCategory: "Reports",
      requiresAuth: true,
      breadcrumb: [
        { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: true }
      ]
    },
    children: [
      {
        path: "",
        name: "Stock Reports Menu",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockReportsMenu"),
      },
      {
        path: "low-stock-items",
        name: "Low Stock Items",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockLowStockItems"),
        meta: {
          breadcrumb: [
            { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: false },
            { label: "Low Stock Items", route: "/reports/stock-reports/low-stock-items", disabled: true }
          ]
        },
      },
      {
        path: "stock-order-forecast",
        name: "Stock Order Forecast",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockOrderForecast"),
        meta: {
          breadcrumb: [
            { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: false },
            { label: "Stock Order Forecast", route: "/reports/stock-reports/stock-order-forecast", disabled: true }
          ]
        },
      },
      {
        path: "products-in-stock",
        name: "Products In Stock",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockProducts"),
        meta: {
          breadcrumb: [
            { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: false },
            { label: "Products In Stock", route: "/reports/stock-reports/products-in-stock", disabled: true }
          ]
        },
      },
      {
        path: "fast-moving-stock",
        name: "Fast Moving Stock",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockFastMovingItems"),
        meta: {
          breadcrumb: [
            { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: false },
            { label: "Fast Moving Stock", route: "/reports/stock-reports/fast-moving-stock", disabled: true }
          ]
        },
      },
      {
        path: "stock-often-replenished",
        name: "Stock Often Replenished",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockOftenReplenished"),
        meta: {
          breadcrumb: [
            { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: false },
            { label: "Stock Often Replenished", route: "/reports/stock-reports/stock-often-replenished", disabled: true }
          ]
        },
      },
      {
        path: "stock-often-adjusted",
        name: "Stock Often Adjusted",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockOftenAdjusted"),
        meta: {
          breadcrumb: [
            { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: false },
            { label: "Stock Often Adjusted", route: "/reports/stock-reports/stock-often-adjusted", disabled: true }
          ]
        },
      },
      {
        path: "stock-value",
        name: "Stock Value",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockValue"),
        meta: {
          breadcrumb: [
            { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: false },
            { label: "Stock Value", route: "/reports/stock-reports/stock-value", disabled: true }
          ]
        },
      },
      {
        path: "stock-value-by-month-end",
        name: "Stock Value By Month End",
        component: () =>
            import(/* webpackChunkName: "group-stock-reports" */ "../components/reports/stock/StockValueByMonthEnd"),
        meta: {
          breadcrumb: [
            { label: "Stock Reports Menu", route: "/reports/stock-reports", disabled: false },
            { label: "Stock Value By Month End", route: "/reports/stock-reports/stock-value-by-month-end", disabled: true }
          ]
        }
      }
    ]
  },
  {
    path: "/reports/location-reports",
    name: "Location Reports",
    component: () =>
        import(/* webpackChunkName: "group-location-reports" */ "../views/reports/ReportsSubmenuView"),
    meta: {
      pageCategory: "Reports",
      requiresAuth: true,
      breadcrumb: [
        { label: "Location Reports Menu", route: "/reports/location-reports", disabled: true }
      ]
    },
    children: [
      {
        path: "",
        name: "Location Reports Menu",
        component: () =>
            import(/* webpackChunkName: "group-location-reports" */ "../components/reports/location/LocationReportsMenu")
      },
      {
        path: "locations-in-use",
        name: "Locations In Use",
        component: () =>
            import(/* webpackChunkName: "group-location-reports" */ "../components/reports/location/LocationsInUse"),
        meta: {
          breadcrumb: [
            { label: "Location Reports Menu", route: "/reports/location-reports", disabled: false },
            { label: "Locations In Use", route: "/reports/location-reports/locations-in-use", disabled: true }
          ]
        },
      },
      {
        path: "inactive-locations",
        name: "Inactive Locations",
        component: () =>
            import(/* webpackChunkName: "group-location-reports" */ "../components/reports/location/LocationsInactive"),
        meta: {
          breadcrumb: [
            { label: "Location Reports Menu", route: "/reports/location-reports", disabled: false },
            { label: "Inactive Locations", route: "/reports/location-reports/inactive-locations", disabled: true }
          ]
        },
      },
      {
        path: "locations-needing-replenishment",
        name: "Locations Needing Replenishment",
        component: () =>
            import(/* webpackChunkName: "group-location-reports" */ "../components/reports/location/LocationsNeedingReplenishment"),
        meta: {
          breadcrumb: [
            { label: "Location Reports Menu", route: "/reports/location-reports", disabled: false },
            { label: "Locations Needing Replenishment", route: "/reports/location-reports/locations-needing-replenishment", disabled: true }
          ]
        },
      },
    ]
  },
  {
    path: "/reports/order-reports",
    name: "Order Reports",
    component: () =>
        import(/* webpackChunkName: "group-order-reports" */ "../views/reports/ReportsSubmenuView"),
    meta: {
      pageCategory: "Reports",
      requiresAuth: true,
      breadcrumb: [
        { label: "Order Reports Menu", route: "/reports/order-reports", disabled: true }
      ]
    },
    children: [
      {
        path: "",
        name: "Order Reports Menu",
        component: () =>
            import(/* webpackChunkName: "group-order-reports" */ "../components/reports/orders/OrderReportsMenu"),
      },
      {
        path: "late-orders",
        name: "Late Orders",
        component: () =>
            import(/* webpackChunkName: "group-order-reports" */ "../components/reports/orders/OrdersLateOrders"),
        meta: {
          breadcrumb: [
            { label: "Order Reports Menu", route: "/reports/order-reports", disabled: false },
            { label: "Late Orders", route: "/reports/order-reports/late-orders", disabled: true }
          ]
        },
      },
      {
        path: "order-fulfillment",
        name: "Order Fulfillment",
        component: () =>
            import(/* webpackChunkName: "group-order-reports" */ "../components/reports/orders/OrderFulfillment"),
        meta: {
          breadcrumb: [
            { label: "Order Reports Menu", route: "/reports/order-reports", disabled: false },
            { label: "Order Fulfillment", route: "/reports/order-reports/order-fulfillment", disabled: true }
          ]
        },
      },
      {
        path: "orders-dispatched-value",
        name: "Orders Dispatched Value",
        component: () =>
            import(/* webpackChunkName: "group-order-reports" */ "../components/reports/orders/OrdersDispatchedValue"),
        meta: {
          breadcrumb: [
            { label: "Order Reports Menu", route: "/reports/order-reports", disabled: false },
            { label: "Orders Dispatched Value", route: "/reports/order-reports/orders-dispatched-value", disabled: true }
          ]
        },
      },
      {
        path: "orders-on-time-vs-late",
        name: "Orders Dispatched On Time Vs Late",
        component: () =>
            import(/* webpackChunkName: "group-order-reports" */ "../components/reports/orders/OrdersDispatchedOnTimeVsLate"),
        meta: {
          breadcrumb: [
            { label: "Order Reports Menu", route: "/reports/order-reports", disabled: false },
            { label: "Orders Dispatched On Time Vs Late", route: "/reports/order-reports/orders-on-time-vs-late", disabled: true }
          ]
        },
      }
    ]
  },
  {
    path: "/shipments",
    name: "Shipments",
    component: () =>
        import(/* webpackChunkName: "group-shipments" */ "../views/shipments/ShipmentsView"),
    meta: { pageCategory: "Shipments", requiresAuth: true },
  },
  {
    path: "/shipments/create/:orderId?",
    name: "Create Shipment",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-shipments" */ "../views/shipments/CreateShipmentView"),
    meta: { pageCategory: "Shipments", requiresAuth: true, requiresWriteAccess: true },
    // Children show in this component"s router-view tags
    children: [
      {
        path: "",
        name: "Shipment Details",
        component: () =>
            import(/* webpackChunkName: "group-shipments" */ "../components/forms/shipments/CreateShipmentDetails"),
      },
      {
        path: "review",
        name: "Shipment Review",
        component: () =>
            import(/* webpackChunkName: "group-shipments" */ "../components/forms/shipments/CreateShipmentReview"),
        beforeEnter: (to, from, next) => {
          // Check user is coming from goods in details:
          if (from.name === "Shipment Details") next()
          else next("not-found")
        },
      },
      {
        path: "confirmed",
        name: "Shipment Confirmed",
        component: () =>
            import(/* webpackChunkName: "group-shipments" */ "../components/forms/shipments/CreateShipmentConfirmed"),
        beforeEnter: (to, from, next) => {
          // Check user is coming from goods in details:
          if (from.name === "Shipment Review") next()
          else next("not-found")
        },
      },
    ],
  },
  {
    path: "/shipments/edit/:id",
    name: "Shipments Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-shipments" */ "../views/shipments/EditShipmentsView"),
    meta: { pageCategory: "Shipments", requiresAuth: true, requiresWriteAccess: true },
  },
  {
    path: "/stock",
    name: "Stock",
    component: () =>
        import(/* webpackChunkName: "group-stock" */ "../views/StockView"),
    meta: { pageCategory: "Stock", requiresAuth: true }
  },
  {
    path: "/stock-checks",
    name: "Stock Checks",
    component: () =>
        import(/* webpackChunkName: "group-stock" */ "../views/stock-checks/StockChecksView")
  },
  {
    path: "/stock-checks-auto",
    name: "Stock Checks Auto",
    component: () =>
        import(/* webpackChunkName: "group-stock" */ "../views/StockChecksAutoView"),
    meta: { pageCategory: "Stock Checks Auto", requiresAuth: true }
  },
  {
    path: "/stock-checks/edit/:id",
    name: "Stock Check Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-stock" */ "../views/stock-checks/EditStockCheckView")
  },
  {
    path: "/stock-holds",
    name: "Stock Holds",
    component: () =>
        import(/* webpackChunkName: "group-stock" */ "../views/StockHoldsView"),
    meta: { pageCategory: "Stock Holds", requiresAuth: true }
  },
  {
    path: "/stock-levels",
    name: "Stock Levels",
    component: () =>
        import(/* webpackChunkName: "group-stock" */ "../views/StockLevelsView"),
    meta: { pageCategory: "Stock Levels", requiresAuth: true }
  },
  {
    path: "/tags",
    name: "Tags",
    component: () =>
        import(/* webpackChunkName: "group-tags" */ "../views/TagsView"),
    meta: { pageCategory: "Tags", requiresAuth: true }
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
        import(/* webpackChunkName: "group-users" */ "../views/UsersView"),
    meta: { pageCategory: "Users", requiresAuth: true }
  },
  {
    path: "/user-groups",
    name: "User Groups",
    component: () =>
        import(/* webpackChunkName: "group-users" */ "../views/UserGroupsView"),
    meta: { pageCategory: "User Groups", requiresAuth: true }
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    component: () =>
        import(/* webpackChunkName: "group-warehouse" */ "../views/WarehousesView"),
    meta: { pageCategory: "Warehouses", requiresAuth: true }
  },
  {
    path: "/warehouse-zones",
    name: "Warehouse Zones",
    component: () =>
        import(/* webpackChunkName: "group-warehouse" */ "../views/WarehouseZonesView"),
    meta: { pageCategory: "Warehouse Zones", requiresAuth: true }
  },
  {
    path: "/works-orders",
    name: "Works Orders",
    component: () =>
        import(/* webpackChunkName: "group-works" */ "../views/works-orders/WorksOrdersView"),
    meta: { pageCategory: "Works Orders", requiresAuth: true }
  },
  {
    path: "/works-order/edit/:id",
    name: "Works Order Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-works" */ "../views/works-orders/EditWorksOrderView"),
    meta: { pageCategory: "Works Orders", requiresAuth: true, requiresWriteAccess: true }
  },
  {
    path: "/works-processes",
    name: "Works Processes",
    component: () =>
        import(/* webpackChunkName: "group-works" */ "../views/works-processes/WorksProcessesView"),
    meta: { pageCategory: "Works Processes", requiresAuth: true }
  },
  {
    path: "/works-processes/create",
    name: "Create Works Process",
    component: () =>
        import(/* webpackChunkName: "group-works" */ "../views/works-processes/CreateWorksProcessView"),
    meta: { pageCategory: "Works Processes", requiresAuth: true, requiresWriteAccess: true },
    children: [
      {
        path: "",
        name: "Works Process Details",
        component: () =>
            import(/* webpackChunkName: "group-works" */ "../components/forms/works-processes/CreateWorksProcessDetails")
      },
      {
        path: "bom-items",
        name: "Works Process BOM Items",
        props: { dataTableEmptyMsg: "Add items to BOM", cancelRedirect: "Works Processes", reorderableRows: true },
        component: () =>
            import(/* webpackChunkName: "group-works" */ "../components/forms/ItemsAndQtyDatatableForm"),
        beforeEnter: (to, from, next) => {
          // Check user is coming from works process details:
          if (from.name === "Works Process Details") next()
          else next("not-found")
        }
      }
    ]
  },
  {
    path: "/works-processes/edit/:id",
    name: "Works Processes Edit",
    props: true,
    component: () =>
        import(/* webpackChunkName: "group-works" */ "../views/works-processes/EditWorksProcessView"),
    meta: { pageCategory: "Works Processes", requiresAuth: true, requiresWriteAccess: true }
  },

  // Error pages
  {
    path: "/:catchAll(.*)", // Match all routes that do not match an existing route
    name: "Not found",
    component: () =>
        import(/* webpackChunkName: "group-error" */ "@/views/errors/NotFoundView")
  },
  {
    path: "/api-error",
    name: "API Error",
    component: () =>
        import(/* webpackChunkName: "group-error" */ "@/views/errors/ApiErrorView")
  },
  {
    path: "/network-error",
    name: "Network Error",
    component: () =>
        import(/* webpackChunkName: "group-error" */ "@/views/errors/NetworkErrorView")
  },
  {
    path: "/permission-error",
    name: "Permission Error",
    component: () =>
        import(/* webpackChunkName: "group-error" */ "@/views/errors/PermissionErrorView")
  },
  {
    path: "/token-error",
    name: "Token Error",
    component: () =>
        import(/* webpackChunkName: "group-error" */ "@/views/errors/JwtTokenErrorView")
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  /* Save a users scroll position between pages */
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition){
      return savedPosition;
    } else{
      return { top: 0 };
    }
  }
})

// Create a general route guard:
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user")

  // If the route requires authentication and user is not logged in:
  if (to.meta.requiresAuth && !loggedIn) {
    // Redirect to log in:
    next("/")
  }

  // If the route requires authentication and write access:
  else if (to.meta.requiresAuth && to.meta.requiresWriteAccess) {
    let pageView = Permissions.checkPageView(to.meta.pageCategory)
    let pageEdit = Permissions.checkPageEdit(to.meta.pageCategory)

    if (pageView && pageEdit) {
      next()
    }
    else {
      // Redirect to permission error page:
      next("permission-error")
    }
  }

  // If the route requires authentication and has a page category:
  else if (to.meta.requiresAuth && to.meta.pageCategory) {
    let pageView = Permissions.checkPageView(to.meta.pageCategory)

    if (pageView) {
      next()
    }
    else {
      // Redirect to permission error page:
      next("permission-error")
    }
  }

  // If a user is logged in and tries to go to the login route via the URL:
  else if (loggedIn && to.name === "Login") {
    next("dashboard")
  }

  else {
    // Continue to route:
    next()
  }

  changePageTitle(to)
})

function changePageTitle (to) {
  switch (to.name) {
    case "Container Edit" :
      document.title = "Container " + to.params.id
      break
    case "Goods In Edit" :
      document.title = "Goods In " + to.params.id
      break
    case "Location Edit" :
      document.title = "Location " + to.params.id
      break
    case "Order Edit" :
      document.title = "Order " + to.params.id
      break
    case "Item Edit" :
      document.title = "Item " + to.params.id
      break
    case "Return Edit" :
      document.title = "Return " + to.params.id
      break
    default :
      document.title = to.name || "Kstore v6"
  }
}

export default router
